import * as React from 'react';
function SvgIgAiCoHostFaq5(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 720 360"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M362.89 332.197c120.695 0 168.863-10.186 199.458-76.289 30.595-66.103-23.925-216.17-203.674-204.033-179.75 12.137-234.996 113.296-199.949 187.193s83.471 93.129 204.165 93.129z"
        fill="#F1F4FF"
      />
      <path
        d="M357.284 322.011c48.482 0 87.784-3.357 87.784-7.5 0-4.142-39.302-7.5-87.784-7.5-48.482 0-87.784 3.358-87.784 7.5 0 4.143 39.302 7.5 87.784 7.5z"
        fill="#DEE3FF"
      />
      <path
        d="M355.594 317.505c33.965 0 61.5-1.903 61.5-4.25s-27.535-4.25-61.5-4.25c-33.966 0-61.5 1.903-61.5 4.25s27.534 4.25 61.5 4.25z"
        fill="#3F5CFF"
        fillOpacity={0.3}
      />
      <path
        d="M341.096 285.061a2.606 2.606 0 01.92 3.186l-2.427 5.6-5.219 12.72a10.904 10.904 0 01-1.649 4.65c-3.674 5.738-9.223 2.636-15.823-1.581l-.27-.173c-6.468-4.145-11.411-7.85-7.798-13.524 3.664-5.752 12-6.981 18.6-2.765.246.159.486.324.721.493l9.554-8.4a2.77 2.77 0 013.391-.206z"
        fill="#C3FFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M308.191 297.025l24.552 14.175a5.785 5.785 0 01-8.291 2.925l-13.816-7.976a6.68 6.68 0 01-2.445-9.124z"
        fill="#633DFF"
      />
      <path
        d="M370.741 282.238a2.603 2.603 0 00-2.39 2.299l-.698 6.063-1.841 13.625a10.911 10.911 0 00-.897 4.852c.313 6.806 6.67 6.894 14.493 6.542l.321-.014c7.674-.356 13.807-1.093 13.515-7.814-.297-6.814-6.902-12.046-14.726-11.694-.292.015-.582.037-.87.066l-4.075-12.051a2.767 2.767 0 00-2.832-1.874z"
        fill="#C3FFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M364.886 309.051h28.35a5.788 5.788 0 01-5.718 6.679h-15.954a6.678 6.678 0 01-6.678-6.679z"
        fill="#633DFF"
      />
      <path
        d="M297.91 190.646l-11.278 3.731a9.805 9.805 0 00-6.727 9.31v28.128a9.806 9.806 0 006.085 9.072l11.92 4.89v-55.131z"
        fill="url(#IgAiCoHostFaq5_svg__paint0_linear_6366_132723)"
      />
      <mask
        id="IgAiCoHostFaq5_svg__a"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={279}
        y={190}
        width={19}
        height={56}
      >
        <path
          d="M297.91 190.646l-11.278 3.731a9.805 9.805 0 00-6.727 9.31v28.128a9.806 9.806 0 006.085 9.072l11.92 4.89v-55.131z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#IgAiCoHostFaq5_svg__a)">
        <path
          d="M288.908 190.646h-9.003v55.131h9.003v-55.131z"
          fill="#4E47FF"
        />
      </g>
      <path
        d="M414.283 190.646l11.278 3.731a9.806 9.806 0 016.726 9.31v28.128a9.807 9.807 0 01-6.084 9.072l-11.92 4.89v-55.131z"
        fill="url(#IgAiCoHostFaq5_svg__paint1_linear_6366_132723)"
      />
      <mask
        id="IgAiCoHostFaq5_svg__b"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={414}
        y={190}
        width={19}
        height={56}
      >
        <path
          d="M414.283 190.646l11.278 3.731a9.806 9.806 0 016.726 9.31v28.128a9.807 9.807 0 01-6.084 9.072l-11.92 4.89v-55.131z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#IgAiCoHostFaq5_svg__b)">
        <path
          d="M423.285 190.646h9.002v55.131h-9.002v-55.131z"
          fill="#4E47FF"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M347.937 160.405c-8.135-6.469-15.293-11.242-21.474-14.321-9.271-4.617-30.063-6.897-36.756-2.428-6.692 4.47-4.556 23.824-1.939 35.92 1.745 8.063 4.333 15.635 7.765 22.714.129-9.742 3.742-18.719 10.841-26.932 7.099-8.212 20.953-13.196 41.563-14.953z"
        fill="#fff"
      />
      <path
        d="M319.74 164.204c3.618-2.261-19.528-20.659-27.156-14.741-7.629 5.917 2.159 44.082 3.665 40.614 1.506-3.468 1.816-6.737 7.211-13.658 5.396-6.921 12.662-9.955 16.28-12.215z"
        fill="#908CFF"
      />
      <mask
        id="IgAiCoHostFaq5_svg__c"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={289}
        y={148}
        width={32}
        height={43}
      >
        <path
          d="M319.74 164.204c3.618-2.261-19.528-20.659-27.156-14.741-7.629 5.917 2.159 44.082 3.665 40.614 1.506-3.468 1.816-6.737 7.211-13.658 5.396-6.921 12.662-9.955 16.28-12.215z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#IgAiCoHostFaq5_svg__c)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M325.701 166.974c3.618-2.26-19.528-20.658-27.156-14.74-7.629 5.917 2.159 44.082 3.665 40.614 1.506-3.468 1.816-6.737 7.211-13.658 5.396-6.921 12.662-9.955 16.28-12.216z"
          fill="#4E47FF"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M364.934 160.42c8.135-6.469 15.293-11.243 21.474-14.321 9.271-4.617 30.063-6.898 36.756-2.428 6.692 4.469 4.556 23.824 1.939 35.919-1.744 8.064-4.333 15.635-7.765 22.715-.129-9.743-3.742-18.72-10.841-26.932-7.099-8.212-20.953-13.197-41.563-14.953z"
        fill="#fff"
      />
      <path
        d="M393.131 164.218c-3.618-2.26 19.528-20.658 27.156-14.74 7.629 5.917-2.159 44.082-3.665 40.614-1.506-3.469-1.816-6.737-7.211-13.659-5.396-6.921-12.662-9.954-16.28-12.215z"
        fill="#908CFF"
      />
      <mask
        id="IgAiCoHostFaq5_svg__d"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={392}
        y={148}
        width={32}
        height={43}
      >
        <path
          d="M393.131 164.218c-3.618-2.26 19.528-20.658 27.156-14.74 7.629 5.917-2.159 44.082-3.665 40.614-1.506-3.469-1.816-6.737-7.211-13.659-5.396-6.921-12.662-9.954-16.28-12.215z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#IgAiCoHostFaq5_svg__d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M387.17 166.989c-3.618-2.26 19.528-20.658 27.156-14.741 7.629 5.918-2.159 44.083-3.665 40.614-1.506-3.468-1.816-6.736-7.211-13.658-5.396-6.921-12.662-9.954-16.28-12.215z"
          fill="#4E47FF"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M340.14 177.646l2.785 3.61a5.113 5.113 0 004.048 1.99h15.677a5.112 5.112 0 003.998-1.926l2.93-3.674H340.14z"
        fill="#211F8A"
      />
      <path
        d="M379.753 287.392a665.277 665.277 0 01-47.314 0c-18.911-.813-36.184-15.955-37.112-32.532-.64-13.823-.64-47.54 0-61.367.928-16.565 18.201-31.718 37.112-32.531a668.852 668.852 0 0147.314 0c18.911.813 36.184 15.966 37.112 32.531.64 13.827.64 47.544 0 61.367-.928 16.577-18.201 31.719-37.112 32.532z"
        fill="url(#IgAiCoHostFaq5_svg__paint2_linear_6366_132723)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M349.713 262.835l2.533-6.76a2.749 2.749 0 015.187.11l2.139 6.546a2.749 2.749 0 001.87 1.793l5.969 1.675a2.347 2.347 0 01.105 4.487l-6.196 2.055a2.748 2.748 0 00-1.734 1.716l-2.165 6.301a2.75 2.75 0 01-5.158.114l-2.568-6.521a2.752 2.752 0 00-1.668-1.593l-6.058-2.074a2.354 2.354 0 01.107-4.485l5.828-1.688a2.75 2.75 0 001.809-1.676z"
        fill="#4E47FF"
      />
      <path
        d="M385.475 173.044h-61.188c-14.12 0-25.566 11.446-25.566 25.566v26.759c0 14.12 11.446 25.566 25.566 25.566h61.188c14.12 0 25.566-11.446 25.566-25.566V198.61c0-14.12-11.446-25.566-25.566-25.566z"
        fill="#221CBF"
      />
      <path
        d="M384.721 174.748h-59.682c-13.178 0-23.861 10.684-23.861 23.862v26.759c0 13.179 10.683 23.862 23.861 23.862h59.682c13.178 0 23.862-10.683 23.862-23.862V198.61c0-13.178-10.684-23.862-23.862-23.862z"
        fill="#645EFF"
      />
      <path
        d="M383.952 177.646h-58.143c-11.295 0-20.453 9.157-20.453 20.453v27.782c0 11.295 9.158 20.452 20.453 20.452h58.143c11.296 0 20.453-9.157 20.453-20.452v-27.782c0-11.296-9.157-20.453-20.453-20.453z"
        fill="url(#IgAiCoHostFaq5_svg__paint3_radial_6366_132723)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M341.715 226.972c3.024-2.088 6.068 1.785 12.794 1.785 6.725 0 10.569-3.873 13.056-1.785 2.487 2.088-1.3 10.037-13.175 10.037-11.874 0-15.698-7.949-12.675-10.037z"
        fill="#FF8383"
      />
      <path
        d="M355.038 225.623c8.899 0 16.113-7.189 16.113-16.056 0-8.868-7.214-16.057-16.113-16.057s-16.113 7.189-16.113 16.057c0 8.867 7.214 16.056 16.113 16.056z"
        fill="url(#IgAiCoHostFaq5_svg__paint4_linear_6366_132723)"
      />
      <path
        d="M350.448 203.195c1.609 0 2.914-1.3 2.914-2.903a2.909 2.909 0 00-2.914-2.903 2.908 2.908 0 00-2.913 2.903 2.908 2.908 0 002.913 2.903z"
        fill="#00FFFA"
        fillOpacity={0.3}
      />
      <path
        d="M334.765 211.044c.046 8.06-2.771 14.579-9.584 14.579-7.734 0-9.706-6.557-9.745-14.618-.039-8.06 2.03-14.579 9.591-14.579 6.967 0 9.7 6.577 9.738 14.618z"
        fill="url(#IgAiCoHostFaq5_svg__paint5_linear_6366_132723)"
      />
      <path
        d="M324.072 204.634c1.46 0 2.643-1.225 2.643-2.736 0-1.511-1.183-2.736-2.643-2.736-1.459 0-2.642 1.225-2.642 2.736 0 1.511 1.183 2.736 2.642 2.736z"
        fill="#fff"
      />
      <path
        d="M325.594 222.99c2.669 0 4.834-1.426 4.834-3.186 0-1.759-2.165-3.185-4.834-3.185-2.67 0-4.834 1.426-4.834 3.185 0 1.76 2.164 3.186 4.834 3.186z"
        fill="#00CBCD"
      />
      <path
        d="M395.052 211.044c.038 8.06-2.778 14.579-9.591 14.579-7.734 0-9.668-6.557-9.745-14.618-.077-8.06 2.03-14.579 9.59-14.579 6.974 0 9.701 6.577 9.746 14.618z"
        fill="url(#IgAiCoHostFaq5_svg__paint6_linear_6366_132723)"
      />
      <path
        d="M384.353 204.634c1.459 0 2.642-1.225 2.642-2.736 0-1.511-1.183-2.736-2.642-2.736-1.46 0-2.643 1.225-2.643 2.736 0 1.511 1.183 2.736 2.643 2.736z"
        fill="#fff"
      />
      <path
        d="M385.874 222.99c2.67 0 4.834-1.426 4.834-3.186 0-1.759-2.164-3.185-4.834-3.185-2.67 0-4.834 1.426-4.834 3.185 0 1.76 2.164 3.186 4.834 3.186z"
        fill="#00CBCD"
      />
      <path
        d="M420.095 240.727c.337 5.424 2.454 9.736 6.349 12.938 3.896 3.201 8.693 5.528 14.391 6.979l-12.372 10.627c-1.805-1.903-4.256-4.822-7.353-8.758-3.097-3.935-5.453-9.368-7.068-16.297l6.053-5.489z"
        fill="url(#IgAiCoHostFaq5_svg__paint7_linear_6366_132723)"
      />
      <path
        d="M441.105 276.789c-6.427 2.083-12.873-.037-14.396-4.736-1.523-4.7 2.453-10.198 8.88-12.282 6.428-2.083 12.873.037 14.397 4.737 1.523 4.699-2.453 10.197-8.881 12.281z"
        fill="#000"
      />
      <path
        d="M299.993 250.281c-8.127-1.317-14.619-3.721-19.478-7.214-4.858-3.492-8.455-10.076-10.791-19.751l14.643 1.086c1.386 6.989 3.893 11.831 7.522 14.526 3.628 2.694 7.122 4.005 10.482 3.931l-2.378 7.422z"
        fill="url(#IgAiCoHostFaq5_svg__paint8_linear_6366_132723)"
      />
      <path
        d="M267.933 220.568c1.409-6.609 6.468-11.13 11.299-10.1 4.832 1.031 7.605 7.223 6.196 13.831-1.409 6.608-6.469 11.13-11.3 10.1-4.831-1.031-7.605-7.223-6.195-13.831z"
        fill="#000"
      />
      <path
        d="M504.793 258.392h-66.5a2 2 0 00-2 2v2.582a2 2 0 002 2h66.5a2 2 0 002-2v-2.582a2 2 0 00-2-2z"
        fill="#908CFF"
      />
      <path d="M502 264.974h-60v8.707h60v-8.707z" fill="#4E47FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M444.001 273.681h56l3.813 34.279a2 2 0 01-1.988 2.221h-60.445a2 2 0 01-1.982-2.266l4.602-34.234z"
        fill="#908CFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M442.001 258.392h56.656l8.136-44.808h-74.011l9.219 44.808z"
        fill="#fff"
      />
      <path
        opacity={0.597}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M449.068 258.187l41.404-.184 9.159-46.235H442l7.068 46.419z"
        fill="#F1F4FF"
      />
      <path
        d="M509.985 72.31l-14.617-5.321c-14.128-5.142-29.749 2.142-34.891 16.27l-5.321 14.617c-5.142 14.128 2.142 29.749 16.27 34.891l14.618 5.321c14.127 5.142 29.749-2.143 34.891-16.27l5.32-14.618c5.142-14.127-2.142-29.749-16.27-34.89z"
        fill="#4E47FF"
      />
      <path
        d="M483.523 122.272c10.899 3.966 22.949-1.653 26.916-12.551 3.967-10.899-1.653-22.95-12.551-26.916-10.899-3.967-22.949 1.652-26.916 12.55-3.967 10.899 1.652 22.95 12.551 26.917z"
        fill="#FF7171"
      />
      <path
        d="M481.115 99.047a2.27 2.27 0 101.553-4.266 2.27 2.27 0 10-1.553 4.266zM499.867 105.872a2.269 2.269 0 101.552-4.266 2.268 2.268 0 00-2.909 1.357 2.268 2.268 0 001.357 2.909z"
        fill="#4E47FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M478.128 102.274l21.845 7.951c-2.466 3.679-6.843 4.375-13.129 2.087s-9.192-5.634-8.716-10.038z"
        fill="#4E47FF"
      />
      <path
        d="M263.817 58.608l-15.319-2.7c-14.806-2.611-28.925 7.275-31.536 22.08l-2.701 15.32c-2.611 14.806 7.276 28.925 22.082 31.536l15.319 2.701c14.806 2.611 28.925-7.275 31.536-22.082l2.701-15.319c2.611-14.806-7.276-28.925-22.082-31.536z"
        fill="#E4E8FF"
      />
      <path
        d="M266.96 82.377l-29.544-5.21a4 4 0 00-4.634 3.245l-.661 3.75a4 4 0 003.244 4.634l29.545 5.21a4 4 0 004.634-3.246l.661-3.749a4 4 0 00-3.245-4.634zM232.835 91.09l33.422 5.893-2.372 13.455a4 4 0 01-4.634 3.245l-25.544-4.505a3.999 3.999 0 01-3.244-4.633l2.372-13.455zM251.564 76.922l-3.882-5.958-7.508-1.324 4.302 6.032 7.088 1.25zM252.58 77.101l5.685-4.27 7.508 1.323-6.106 4.197-7.087-1.25z"
        fill="#4E47FF"
      />
      <path
        d="M254.066 80.104l-4.924-.869-5.586 31.68 4.924.868 5.586-31.68z"
        fill="#E4E8FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M484.961 220.272l-4.322 7.419a.544.544 0 01-.992-.117l-2.406-7.949a.546.546 0 00-.424-.379l-8.312-1.503a.546.546 0 01-.217-.982l7.05-4.956a.545.545 0 00.229-.502l-.866-8.347a.546.546 0 01.862-.498l6.796 4.923c.16.116.37.137.549.053l7.817-3.627a.545.545 0 01.743.679l-2.855 7.949a.545.545 0 00.116.557l5.682 6.058a.547.547 0 01-.396.918l-8.585.034a.543.543 0 00-.469.27z"
        fill="#FF7171"
      />
      <path
        d="M456.079 196.868c3.409-2.86 3.125-8.811-.634-13.291-3.76-4.48-9.571-5.793-12.98-2.933-3.409 2.861-3.125 8.811.635 13.291 3.759 4.481 9.57 5.794 12.979 2.933z"
        fill="#FF7171"
      />
      <path
        d="M210.926 116.149l-13.472 7.778c-13.02 7.517-17.481 24.166-9.964 37.186l7.778 13.472c7.517 13.02 24.166 17.481 37.186 9.964l13.472-7.778c13.02-7.517 17.481-24.166 9.964-37.186l-7.778-13.472c-7.517-13.02-24.166-17.481-37.186-9.964z"
        fill="#4E47FF"
      />
      <path
        d="M210.44 130.864c11.479-6.628 25.821-3.276 32.034 7.485 6.214 10.762 1.945 24.858-9.534 31.486-.399.23-.802.449-1.208.655l3.252 5.633-9.122-3.504c-9.768 2.223-19.975-1.641-24.957-10.27-6.213-10.761-1.944-24.858 9.535-31.485z"
        fill="#FF7171"
      />
      <path
        d="M210.657 151.237l17.317-9.998M216.657 161.629l11.995-6.925"
        stroke="#4E47FF"
        strokeWidth={5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M550.528 161.682l-10.008-8.398c-9.672-8.116-24.092-6.854-32.209 2.818l-8.397 10.008c-8.116 9.672-6.854 24.092 2.818 32.208l10.008 8.398c9.672 8.116 24.092 6.854 32.208-2.818l8.398-10.008c8.116-9.672 6.854-24.092-2.818-32.208z"
        fill="#E4E8FF"
      />
      <path
        d="M527.171 157.761a1.39 1.39 0 11-2.74-.48l.597-3.387a1.392 1.392 0 012.741.48l-.598 3.387zm-17.399 31.531c.682 3.632 1.587 6.945 2.339 9.425.147.524.595.934 1.169 1.004 8.93 1.087 23.859 1.727 30.977-5.073 2.599-2.482 4.151-5.904 3.983-10.583-.195-5.34-3.856-9.475-8.942-10.921a13.825 13.825 0 00-4.269-.522c-.977-6.759-6.653-11.978-13.56-11.018-9.001 1.251-12.322 8.107-12.679 16.247-.164 3.777.293 7.77.981 11.444l.001-.003zm7.552-17.455a1.394 1.394 0 01-2.275-1.609c2.186-3.092 5.747-5.014 9.483-3.894a1.393 1.393 0 01-.804 2.667c-2.532-.759-4.915.726-6.407 2.836h.003zm13.713-10.347a1.393 1.393 0 01-1.6-2.281l2.816-1.974a1.393 1.393 0 011.6 2.281l-2.815 1.972-.001.002zm-9.234-3.628a1.394 1.394 0 01-2.28 1.6l-1.972-2.817a1.393 1.393 0 012.281-1.599l1.971 2.816z"
        fill="#4E47FF"
      />
      <path
        d="M529.384 178.981l-8.141 9.702M520.297 179.622l10.241 8.594"
        stroke="#E4E8FF"
        strokeWidth={5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="IgAiCoHostFaq5_svg__paint0_linear_6366_132723"
          x1={1180.13}
          y1={3475.92}
          x2={1180.13}
          y2={5703.76}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#F9FFFE" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaq5_svg__paint1_linear_6366_132723"
          x1={-467.938}
          y1={3475.92}
          x2={-467.938}
          y2={5703.76}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#F9FFFE" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaq5_svg__paint2_linear_6366_132723"
          x1={356.096}
          y1={249.192}
          x2={356.096}
          y2={287.813}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#C1FFFF" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaq5_svg__paint4_linear_6366_132723"
          x1={1950.24}
          y1={1799.18}
          x2={1950.24}
          y2={3404.85}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#1493AB" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaq5_svg__paint5_linear_6366_132723"
          x1={1281.96}
          y1={1656.3}
          x2={1281.96}
          y2={3116.18}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#1493AB" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaq5_svg__paint6_linear_6366_132723"
          x1={1342.63}
          y1={1656.3}
          x2={1342.63}
          y2={3116.18}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#1493AB" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaq5_svg__paint7_linear_6366_132723"
          x1={-898.835}
          y1={240.727}
          x2={-898.835}
          y2={3295.2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBFFFF" />
          <stop offset={1} stopColor="#E3FFFE" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaq5_svg__paint8_linear_6366_132723"
          x1={1428.38}
          y1={-160.419}
          x2={207.668}
          y2={-3514.3}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBFFFF" />
          <stop offset={1} stopColor="#E3FFFE" />
        </linearGradient>
        <radialGradient
          id="IgAiCoHostFaq5_svg__paint3_radial_6366_132723"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(5075.30689 6032.0763 -4198.71517 3532.74178 1431.7 3215.48)"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#fff" />
        </radialGradient>
      </defs>
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgIgAiCoHostFaq5);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
