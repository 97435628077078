/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 *
 * 2021/09/28
 * fix k8s use same docker image name between prod and sta
 */

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions, import/first, import/no-extraneous-dependencies */
import '!file-loader?name=[name].[ext]!./favicon.ico';
import 'file-loader?name=[name].[ext]!./.htaccess';
import 'regenerator-runtime/runtime';
import 'sanitize.css/sanitize.css';
import './modernizr.min';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';

import App from '@17live/app/containers/App';
// Import init App actions from App
import { initApp } from '@17live/app/containers/App/actions';
import { RootProviders } from '@17live/app/containers/RootProviders/RootProviders';
import { initBraze } from '@17live/app/services/Braze';
import { fingerprintInit } from '@17live/app/services/fingerprint';
// Import perimeterx
import { loadPerimeterx } from '@17live/app/services/Perimeterx';

// Import analytics
import { initAnalytics } from './services/Analytics';
import getIsDevToolsEnabled from './services/DevTools/getIsDevToolsEnabled';
import { initSentry } from './services/Sentry';
import registerServiceWorker from './serviceWorkerRegistration';
/* eslint-enable import/no-unresolved, import/extensions, import/first, import/no-extraneous-dependencies */
import configureStore from './store/configureStore';

const store = configureStore({});

initSentry();

initBraze();

// lazily load third party analytics
initAnalytics();

// lazily load third party perimeterx
loadPerimeterx();

// Init app actions
store.dispatch(initApp());

const render = () => {
  ReactDOM.render(
    <RootProviders>
      <App />
    </RootProviders>,
    document.getElementById('app')
  );
};

registerServiceWorker();

// Hot reloadable translation json files
if (module.hot) {
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', '@17live/app/containers/App'], () => {
    render();
  });
}

fingerprintInit().then(render);

// for local and uat env
if (
  process.env.DEPLOY_ENV !== 'production' &&
  process.env.DEPLOY_ENV !== 'stage'
) {
  // eslint-disable-next-line global-require, import/no-extraneous-dependencies
  require('./utils/helpers/vConsole');
}

// Inject testing helper scripting utilities
if (getIsDevToolsEnabled()) {
  import(
    /* webpackChunkName: "Scripting" */ './services/Scripting'
  ).then(Scripting => Scripting.default(store));
}
