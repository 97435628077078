/*
 *
 * Gift constants
 *
 */
export const GET_GIFT_INFO = 'app/Gift/GET_GIFT_INFO';
export const SET_GIFT_INFO = 'app/Gift/SET_GIFT_INFO';
export const SET_GIFTS_ONLY = 'app/Gift/SET_GIFTS_ONLY';
export const GET_GIFT_INFO_WITH_CHECK_LOADING =
  'app/Gift/GET_GIFT_INFO_WITH_CHECK_LOADING';
export const RESET_GIFT_INFO = 'app/Gift/RESET_GIFT_INFO';
export const ON_RECEIVE_GIFT = 'app/Gift/ON_RECEIVE_GIFT';
export const RECEIVED_GIFT = 'app/Gift/RECEIVED_GIFT';
export const SHOW_GIFT_ANIMATION = 'app/Gift/SHOW_GIFT_ANIMATION';
export const SHOW_POKE_ANIMATION = 'app/Gift/SHOW_POKE_ANIMATION';
export const SHOW_RED_ENVELOPE_ANIMATION =
  'app/Gift/SHOW_RED_ENVELOPE_ANIMATION';
export const SHOW_ANIMATION = 'app/Gift/SHOW_ANIMATION';
export const SHOW_BONUS_BOX_ANIMATION = 'app/Gift/SHOW_BONUS_BOX_ANIMATION';
export const HANDLE_ANIMATION_END = 'app/Gift/HANDLE_ANIMATION_END';
export const PUSH_COMBO_MESSAGE = 'app/Gift/PUSH_COMBO_MESSAGE';
export const REMOVE_COMBO_MESSAGE = 'app/Gift/REMOVE_COMBO_MESSAGE';
export const SET_LUCKY_BAG_INFO = 'app/Gift/SET_LUCKY_BAG_INFO';
export const SET_LUCKY_BAG_CEILING_IDS = 'app/Gift/SET_LUCKY_BAG_CEILING_IDS';
export const SET_SLOTS_INFO = 'app/Gift/SET_SLOTS_INFO';
export const GET_SEND_HISTORY = 'app/Gift/GET_SEND_HISTORY';
export const SET_SEND_HISTORY = 'app/Gift/SET_SEND_HISTORY';
export const GET_BAGGAGE_INFO = 'app/Gift/GET_BAGGAGE_INFO';
export const SET_BAGGAGE_INFO = 'app/Gift/SET_BAGGAGE_INFO';
export const GET_GOODS_INFO = 'app/Gift/GET_GOODS_INFO';
export const SET_GOODS_INFO = 'app/Gift/SET_GOODS_INFO';
export const SET_LEVEL_UP_INFO = 'app/Gift/SET_LEVEL_UP_INFO';
export const UPDATE_LEVEL_UP_INFO = 'app/Gift/UPDATE_LEVEL_UP_INFO';
export const GET_LUCKY_BAG_INFO = 'app/Gift/GET_LUCKY_BAG_INFO';
export const GET_LUCKY_BAG_CEILING_INFO = 'app/Gift/GET_LUCKY_BAG_CEILING_INFO';
export const UPDATE_LUCKY_BAG_CEILING_INFO =
  'app/Gift/UPDATE_LUCKY_BAG_CEILING_INFO';
export const KEEP_LUCKY_BAG_CEILING_INFO_POLLING =
  'app/Gift/KEEP_LUCKY_BAG_CEILING_INFO_POLLING';
export const GET_SLOTS_INFO = 'app/Gift/GET_SLOTS_INFO';
export const UPDATE_BAGGAGE_INFO = 'app/Gift/UPDATE_BAGGAGE_INFO';
export const GET_CUSTOM_GIFT_INFO = 'app/Gift/GET_CUSTOM_GIFT_INFO';
export const SET_CUSTOM_GIFT_INFO = 'app/Gift/SET_CUSTOM_GIFT_INFO';
export const GET_ANIMATION_SRC_LIST = 'app/Gift/GET_ANIMATION_SRC_LIST';
export const SET_ANIMATION_SRC_LIST = 'app/Gift/SET_ANIMATION_SRC_LIST';
export const SET_GIFT_EVENT_INFO = 'app/Gift/SET_GIFT_EVENT_INFO';

export const COMBO_MESSAGE_DISPLAY_TIME = 1000;

export const GIFT_TREASURE_BOX = 'treasureChest';
export const GIFT_SLOT = 'slot';
export const SLOTS_TYPES = {
  [GIFT_SLOT]: 1,
  [GIFT_TREASURE_BOX]: 2,
};

export const MIME_TYPE = 'video/webm';
export const AUDIO_CODEC = 'opus';
export const VIDEO_CODECS = [
  'vp9', // chrome
  'vp8', // firefox
];

/**
 * styles
 */
export const AVATAR_SIZE: number = 36;
export const TRANSITION_TIME = 300; // ms
export const DEFAULT_GIFT_ANIMATION_DURATION = 3; // seconds
export const LOTTO_BOARD_SIZES: { width: number; height: number } = {
  width: 316,
  height: 100,
};
export const SLOT_ANIMATION_STYLES: { [key: string]: string } = {
  font: '25.7px PingFangTC',
  color: '#FFE690',
  textAlign: 'center',
  textBaseline: 'top',
};

export enum ReceiverGroup {
  RECEIVER_GROUP_SINGLE,
  RECEIVER_GROUP_NEWBIES,
  RECEIVER_GROUP_ALL,
  RECEIVER_GROUP_TOP_SUPERFAN,
}
