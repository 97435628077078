import * as React from 'react';
function SvgIgAiCoHostFaq3(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 720 360"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M334.89 332.197c120.694 0 168.862-10.186 199.457-76.289 30.595-66.103-23.924-216.17-203.674-204.033C150.924 64.012 95.678 165.17 130.725 239.068c35.046 73.897 83.47 93.129 204.165 93.129z"
        fill="#F1F4FF"
      />
      <path
        d="M329.284 322.011c48.482 0 87.784-3.358 87.784-7.5 0-4.142-39.302-7.5-87.784-7.5-48.482 0-87.784 3.358-87.784 7.5 0 4.142 39.302 7.5 87.784 7.5z"
        fill="#DEE3FF"
      />
      <path
        d="M327.593 317.505c33.966 0 61.5-1.903 61.5-4.25s-27.534-4.25-61.5-4.25c-33.965 0-61.5 1.903-61.5 4.25s27.535 4.25 61.5 4.25z"
        fill="#3F5CFF"
        fillOpacity={0.3}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M257.831 113.003c-25.489 0-46.153-20.664-46.153-46.154 0-25.49 20.664-46.153 46.153-46.153h56.016c25.489 0 46.153 20.663 46.153 46.153s-20.664 46.154-46.153 46.154h-18.355l-9.653 15.693-9.218-15.693h-18.79z"
        fill="#E4E8FF"
      />
      <path
        d="M313.328 43.93a2.368 2.368 0 012.584-.092l11.559 6.959a2.367 2.367 0 11-2.441 4.055l-10.238-6.163-8.62 6.07a2.368 2.368 0 01-2.301.239l-.159-.076-12.137-6.347-10.588 6.285a2.367 2.367 0 01-2.235.097l-.161-.086-10.717-6.224-9.353 6.155a2.368 2.368 0 01-2.346.146l-.158-.085-11.793-6.959a2.368 2.368 0 012.406-4.077l10.521 6.209 9.345-6.148c.7-.46 1.586-.514 2.332-.153l.158.084 10.78 6.26 10.529-6.249a2.367 2.367 0 012.135-.142l.17.08 12.025 6.29 8.703-6.127zM313.328 62.077a2.368 2.368 0 012.584-.093l11.559 6.959a2.367 2.367 0 11-2.441 4.055l-10.238-6.163-8.62 6.07a2.368 2.368 0 01-2.301.239l-.159-.076-12.137-6.347-10.588 6.285a2.367 2.367 0 01-2.235.097l-.161-.086-10.717-6.224-9.353 6.155a2.368 2.368 0 01-2.346.146l-.158-.085-11.793-6.959a2.368 2.368 0 012.406-4.077l10.521 6.209 9.345-6.148c.7-.46 1.586-.514 2.332-.153l.158.084 10.78 6.26 10.529-6.249a2.367 2.367 0 012.135-.142l.17.08 12.025 6.29 8.703-6.127zM313.328 81.8a2.368 2.368 0 012.584-.092l11.559 6.958a2.367 2.367 0 11-2.441 4.056l-10.238-6.164-8.62 6.071a2.368 2.368 0 01-2.301.238l-.159-.076-12.137-6.347-10.588 6.285a2.366 2.366 0 01-2.235.097l-.161-.085-10.717-6.225-9.353 6.155a2.367 2.367 0 01-2.346.147l-.158-.086-11.793-6.958a2.368 2.368 0 012.406-4.077l10.521 6.208 9.345-6.147c.7-.46 1.586-.514 2.332-.153l.158.084 10.78 6.26 10.529-6.249a2.367 2.367 0 012.135-.143l.17.081 12.025 6.29 8.703-6.128z"
        fill="#C7CFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M195.279 188.908h34.75c15.813 0 28.632 12.819 28.632 28.632s-12.819 28.632-28.632 28.632h-34.75c-15.813 0-28.632-12.819-28.632-28.632s12.819-28.632 28.632-28.632zm11.657 57.264l5.718 9.736 5.989-9.736h-11.707z"
        fill="#E4E8FF"
      />
      <path
        d="M237.724 208.212a1.47 1.47 0 010 2.937h-50.14a1.469 1.469 0 110-2.937h50.14zM237.724 217.212a1.47 1.47 0 010 2.937h-50.14a1.469 1.469 0 110-2.937h50.14zM217.724 226.212a1.47 1.47 0 010 2.937h-30.14a1.469 1.469 0 110-2.937h30.14z"
        fill="#C7CFFF"
      />
      <path
        d="M400.344 51.196h82.104c37.527 0 67.948 30.421 67.948 67.948 0 37.527-30.421 67.948-67.948 67.948h-82.104c-37.527 0-67.948-30.421-67.948-67.948 0-37.527 30.421-67.948 67.948-67.948zm27.504 135.896l13.548 23.104 14.188-23.104h-27.736z"
        fill="#4E47FF"
      />
      <path
        d="M481.793 85.404a3.485 3.485 0 013.808-.136l16.99 10.244a3.485 3.485 0 11-3.598 5.968l-15.043-9.07-12.664 8.934a3.487 3.487 0 01-3.419.339l-.207-.1-17.832-9.341-15.558 9.249a3.484 3.484 0 01-3.337.123l-.196-.106-15.744-9.16-13.743 9.058a3.483 3.483 0 01-3.497.196l-.193-.106-17.333-10.245a3.484 3.484 0 113.546-6l15.459 9.138 13.729-9.047a3.484 3.484 0 013.476-.207l.193.105 15.84 9.214 15.47-9.197a3.484 3.484 0 013.194-.19l.203.098 17.672 9.257 12.784-9.018zM481.793 112.119a3.486 3.486 0 013.808-.137l16.99 10.245a3.484 3.484 0 11-3.598 5.968l-15.043-9.069-12.664 8.933a3.487 3.487 0 01-3.419.339l-.207-.1-17.832-9.341-15.558 9.249a3.484 3.484 0 01-3.337.123l-.196-.106-15.744-9.159-13.743 9.057a3.485 3.485 0 01-3.497.196l-.193-.106-17.333-10.245a3.484 3.484 0 113.546-5.999l15.459 9.137 13.729-9.047a3.483 3.483 0 013.476-.207l.193.105 15.84 9.214 15.47-9.198a3.484 3.484 0 013.194-.189l.203.098 17.672 9.257 12.784-9.018zM481.793 141.157a3.485 3.485 0 013.808-.137l16.99 10.245a3.484 3.484 0 11-3.598 5.968l-15.043-9.07-12.664 8.933a3.484 3.484 0 01-3.419.339l-.207-.1-17.832-9.341-15.558 9.25a3.484 3.484 0 01-3.337.122l-.196-.105-15.744-9.16-13.743 9.057a3.485 3.485 0 01-3.497.197l-.193-.107-17.333-10.244a3.485 3.485 0 113.546-6l15.459 9.137 13.729-9.046a3.483 3.483 0 013.476-.207l.193.104 15.84 9.214 15.47-9.197a3.484 3.484 0 013.194-.19l.203.098 17.672 9.257 12.784-9.017z"
        fill="#FF7171"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M217.202 200.769l10.003-4.665.028.059a5.518 5.518 0 01-10.004 4.664l-.027-.058z"
        fill="#908CFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M200.773 152.24c9.04-4.215 19.823-.223 24.085 8.917a18.299 18.299 0 01.688 13.865c-.398 1.941-.59 3.667-.577 5.179.015 1.836.273 3.559.773 5.168l-15.641 7.294c-.517-1.047-1.61-2.254-3.278-3.621-1.484-1.216-3.159-2.342-5.026-3.378a18.303 18.303 0 01-9.676-9.242c-4.262-9.14-.388-19.966 8.652-24.182z"
        fill="#fff"
      />
      <path
        d="M213.054 166.731a1.011 1.011 0 011.72.337l6.927 20.306a1.01 1.01 0 11-1.914.652l-6.36-18.644-.082.097-.653.751-.241.278-.897 1.031-.756.871-.189.218-.048.054-.011.014-.091.093a1.014 1.014 0 01-.673.259h-.093l-.28.001-1.119.004-2.598.01h-.106l10.081 16.889a1.011 1.011 0 01-.258 1.325l-.092.061a1.01 1.01 0 01-1.386-.35l-10.989-18.411a1.011 1.011 0 01.865-1.529l4.476-.016 1.119-.004h-.086l.025-.029.217-.249.109-.125.357-.412 3.026-3.482z"
        fill="#908CFF"
      />
      <path
        d="M210.101 192.663l15.641-7.293 4.031 8.645a.808.808 0 01-.391 1.074l-14.175 6.61a.809.809 0 01-1.075-.391l-4.031-8.645z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.697 152.981l-7.896-4.297-1.223 2.125 9.119 2.172zM205.543 147.457l1.783-8.811 2.414.43-4.197 8.381zM199.728 150.275l-3.538-10.162-2.025.945 5.563 9.217z"
        fill="#FF7171"
      />
      <path
        d="M512.783 73.696c12.426 0 22.5-10.074 22.5-22.5 0-12.427-10.074-22.5-22.5-22.5-12.427 0-22.5 10.073-22.5 22.5 0 12.426 10.073 22.5 22.5 22.5z"
        fill="#FF7171"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M525.669 46.711l-7.224 5.335-2.343 12.106-16.205-13.598 11.702-4.252 3.975-8.062 10.095 8.471z"
        fill="#fff"
      />
      <path
        d="M516.538 37.091a1.5 1.5 0 00-2.309.486l-3.708 7.52-11.137 4.047a1.5 1.5 0 00-.556 2.463l.104.096 16.205 13.598a1.5 1.5 0 002.437-.864l2.233-11.532 6.753-4.988a1.5 1.5 0 00.184-2.253l-.111-.102-10.095-8.471zm-.463 3.527l7.171 6.017-5.692 4.205c-.26.191-.449.46-.543.765l-.039.156-1.858 9.603-12.261-10.289 9.258-3.363a1.5 1.5 0 00.764-.62l.07-.127 3.13-6.347z"
        fill="#fff"
      />
      <path
        d="M507.446 55.674l-4.975 5.93 2.298 1.928 4.975-5.93-2.298-1.928z"
        fill="#fff"
      />
      <path
        d="M387.292 185.519l12.014 3.974a10.447 10.447 0 017.165 9.918v29.963c0 4.238-2.56 8.057-6.481 9.665l-12.698 5.209v-58.729z"
        fill="url(#igAiCoHostFAQ3_svg__paint0_linear_4235_199473)"
      />
      <mask
        id="igAiCoHostFAQ3_svg__a"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={387}
        y={185}
        width={20}
        height={60}
      >
        <path
          d="M387.292 185.519l12.014 3.974a10.447 10.447 0 017.165 9.918v29.963c0 4.238-2.56 8.057-6.481 9.665l-12.698 5.209v-58.729z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#igAiCoHostFAQ3_svg__a)">
        <path
          d="M396.882 185.519h9.589v58.729h-9.589v-58.729z"
          fill="#4E47FF"
        />
      </g>
      <path
        d="M263.324 185.519l-12.015 3.974a10.447 10.447 0 00-7.165 9.918v29.963c0 4.238 2.561 8.057 6.482 9.665l12.698 5.209v-58.729z"
        fill="url(#igAiCoHostFAQ3_svg__paint1_linear_4235_199473)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M333.999 153.304c8.666-6.891 16.291-11.976 22.875-15.255 9.877-4.919 32.026-7.348 39.155-2.587 7.13 4.761 4.854 25.379 2.066 38.264-1.858 8.59-4.616 16.656-8.272 24.197-.137-10.378-3.986-19.941-11.548-28.69-7.563-8.748-22.321-14.058-44.276-15.929z"
        fill="#fff"
      />
      <path
        d="M364.036 157.351c-3.854-2.408 20.802-22.007 28.929-15.703 8.126 6.304-2.301 46.959-3.905 43.265-1.604-3.695-1.934-7.177-7.682-14.55-5.747-7.373-13.488-10.604-17.342-13.012z"
        fill="#908CFF"
      />
      <mask
        id="igAiCoHostFAQ3_svg__b"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={363}
        y={140}
        width={34}
        height={46}
      >
        <path
          d="M364.036 157.351c-3.854-2.408 20.802-22.007 28.929-15.703 8.126 6.304-2.301 46.959-3.905 43.265-1.604-3.695-1.934-7.177-7.682-14.55-5.747-7.373-13.488-10.604-17.342-13.012z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#igAiCoHostFAQ3_svg__b)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M357.686 160.302c-3.854-2.408 20.803-22.006 28.929-15.702 8.127 6.303-2.3 46.959-3.905 43.264-1.604-3.694-1.934-7.176-7.681-14.549-5.748-7.373-13.489-10.605-17.343-13.013z"
          fill="#4E47FF"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M315.892 153.32c-8.665-6.891-16.29-11.976-22.875-15.256-9.876-4.918-32.025-7.348-39.155-2.586-7.129 4.761-4.854 25.379-2.066 38.264 1.859 8.59 4.616 16.655 8.273 24.196.136-10.378 3.986-19.941 11.548-28.689 7.562-8.748 22.321-14.058 44.275-15.929z"
        fill="#fff"
      />
      <path
        d="M285.856 157.366c3.853-2.408-20.803-22.006-28.929-15.702-8.127 6.303 2.3 46.959 3.904 43.264 1.604-3.694 1.934-7.176 7.682-14.549 5.748-7.373 13.489-10.605 17.343-13.013z"
        fill="#908CFF"
      />
      <mask
        id="igAiCoHostFAQ3_svg__c"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={253}
        y={140}
        width={34}
        height={46}
      >
        <path
          d="M285.856 157.366c3.853-2.408-20.803-22.006-28.929-15.702-8.127 6.303 2.3 46.959 3.904 43.264 1.604-3.694 1.934-7.176 7.682-14.549 5.748-7.373 13.489-10.605 17.343-13.013z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#igAiCoHostFAQ3_svg__c)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M292.205 160.318c3.854-2.408-20.802-22.006-28.928-15.703-8.127 6.304 2.3 46.96 3.904 43.265 1.604-3.695 1.934-7.177 7.682-14.549 5.748-7.373 13.489-10.605 17.342-13.013z"
          fill="#4E47FF"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M342.305 171.67l-2.967 3.846a5.445 5.445 0 01-4.312 2.12h-16.7a5.444 5.444 0 01-4.259-2.052l-3.121-3.914h31.359z"
        fill="#211F8A"
      />
      <path
        d="M300.106 288.58c16.803.597 33.604.597 50.403 0 20.145-.867 38.545-16.997 39.534-34.655.682-14.726.682-50.644 0-65.373-.989-17.646-19.389-33.788-39.534-34.655a713.035 713.035 0 00-50.403 0c-20.144.867-38.545 17.009-39.534 34.655-.682 14.729-.682 50.647 0 65.373.989 17.658 19.39 33.788 39.534 34.655z"
        fill="url(#igAiCoHostFAQ3_svg__paint2_linear_4235_199473)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M332.107 262.419l-2.698-7.201a2.928 2.928 0 00-5.526.118l-2.278 6.972a2.929 2.929 0 01-1.992 1.911l-6.359 1.784a2.5 2.5 0 00-.111 4.78l6.6 2.189a2.931 2.931 0 011.848 1.828l2.305 6.712a2.927 2.927 0 005.494.122l2.737-6.946a2.93 2.93 0 011.776-1.698l6.454-2.21a2.506 2.506 0 00-.114-4.777l-6.208-1.798a2.932 2.932 0 01-1.928-1.786z"
        fill="#4E47FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M359.194 166.768h-58.568c-15.041 0-27.234 12.193-27.234 27.235v28.506c0 15.041 12.193 27.234 27.234 27.234h58.568c15.041 0 27.234-12.193 27.234-27.234v-28.506c0-15.042-12.193-27.235-27.234-27.235z"
        fill="#221CBF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M358.391 168.583h-56.962c-14.039 0-25.419 11.381-25.419 25.42v28.505c0 14.039 11.38 25.419 25.419 25.419h56.962c14.039 0 25.419-11.38 25.419-25.419v-28.505c0-14.039-11.38-25.42-25.419-25.42z"
        fill="#645EFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M357.572 171.67h-55.323c-12.033 0-21.788 9.755-21.788 21.788v29.595c0 12.033 9.755 21.788 21.788 21.788h55.323c12.033 0 21.787-9.755 21.787-21.788v-29.595c0-12.033-9.754-21.788-21.787-21.788z"
        fill="url(#igAiCoHostFAQ3_svg__paint3_radial_4235_199473)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M256.793 188.632h-.966c-6.85 0-12.403 5.553-12.403 12.402v25.632c0 6.849 5.553 12.402 12.403 12.402h.966c6.85 0 12.403-5.553 12.403-12.402v-25.632c0-6.849-5.553-12.402-12.403-12.402z"
        fill="#4E47FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M253.94 193.366a6.821 6.821 0 00-6.821 6.821v25.632a6.821 6.821 0 0013.643 0v-25.632a6.821 6.821 0 00-6.822-6.821z"
        fill="#908CFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M256.236 194.144a4.526 4.526 0 00-4.526 4.526v29.445a4.525 4.525 0 009.052 0V198.67c0-2.5-2.027-4.526-4.526-4.526z"
        fill="#221CBF"
      />
      <path
        d="M335.53 222.779c-9.48 0-17.165-7.658-17.165-17.104 0-9.447 7.685-17.105 17.165-17.105s17.165 7.658 17.165 17.105c0 9.446-7.685 17.104-17.165 17.104z"
        fill="url(#igAiCoHostFAQ3_svg__paint4_linear_4235_199473)"
      />
      <path
        d="M340.419 198.887a3.098 3.098 0 01-3.104-3.092 3.099 3.099 0 013.104-3.093 3.098 3.098 0 013.103 3.093 3.097 3.097 0 01-3.103 3.092z"
        fill="#00FFFA"
        fillOpacity={0.3}
      />
      <path
        d="M354.645 207.248c-.048 8.587 2.953 15.531 10.21 15.531 8.239 0 10.34-6.985 10.381-15.572.041-8.586-2.163-15.531-10.216-15.531-7.422 0-10.334 7.006-10.375 15.572z"
        fill="url(#igAiCoHostFAQ3_svg__paint5_linear_4235_199473)"
      />
      <path
        d="M366.036 200.42c-1.555 0-2.815-1.305-2.815-2.915 0-1.609 1.26-2.914 2.815-2.914 1.554 0 2.815 1.305 2.815 2.914 0 1.61-1.261 2.915-2.815 2.915z"
        fill="#fff"
      />
      <path
        d="M364.415 219.974c-2.843 0-5.149-1.519-5.149-3.393 0-1.875 2.306-3.394 5.149-3.394 2.844 0 5.15 1.519 5.15 3.394 0 1.874-2.306 3.393-5.15 3.393z"
        fill="#00CBCD"
      />
      <path
        d="M292.078 207.248c-.042 8.587 2.959 15.531 10.216 15.531 8.239 0 10.299-6.985 10.381-15.572.083-8.586-2.162-15.531-10.216-15.531-7.429 0-10.333 7.006-10.381 15.572z"
        fill="url(#igAiCoHostFAQ3_svg__paint6_linear_4235_199473)"
      />
      <path
        d="M303.475 200.42c-1.555 0-2.815-1.305-2.815-2.915 0-1.609 1.26-2.914 2.815-2.914s2.815 1.305 2.815 2.914c0 1.61-1.26 2.915-2.815 2.915z"
        fill="#fff"
      />
      <path
        d="M301.855 219.974c-2.844 0-5.15-1.519-5.15-3.393 0-1.875 2.306-3.394 5.15-3.394 2.844 0 5.149 1.519 5.149 3.394 0 1.874-2.305 3.393-5.149 3.393z"
        fill="#00CBCD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M346.442 224.817c-1.674-.814-4.814 1.612-11.54 1.612-6.725 0-11.221-3.718-13.451-2.457-2.229 1.261.892 9.878 12.745 10.307 11.853.43 13.919-8.647 12.246-9.462z"
        fill="#FF8383"
      />
      <path
        d="M244.144 236.38c-4.537 10.985-3.235 18.555 3.905 22.711 10.709 6.234 22.136-9.348 17.215-13.675-3.281-2.885-6.051-2.226-8.31 1.978l-12.81-11.014z"
        fill="url(#igAiCoHostFAQ3_svg__paint7_linear_4235_199473)"
      />
      <path
        d="M263.254 235.678c-.257-7.193-4.728-12.873-9.987-12.686-5.259.188-9.314 6.171-9.058 13.364.256 7.194 4.727 12.873 9.986 12.686 5.259-.188 9.315-6.171 9.059-13.364z"
        fill="#000"
      />
      <path
        d="M408.797 236.38c4.537 10.985 3.235 18.555-3.905 22.711-10.709 6.234-22.136-9.348-17.215-13.675 3.281-2.885 6.051-2.226 8.31 1.978l12.81-11.014z"
        fill="url(#igAiCoHostFAQ3_svg__paint8_linear_4235_199473)"
      />
      <path
        d="M389.687 235.678c.257-7.193 4.727-12.873 9.986-12.686 5.259.188 9.315 6.171 9.059 13.364-.256 7.194-4.727 12.873-9.986 12.686-5.259-.188-9.315-6.171-9.059-13.364z"
        fill="#000"
      />
      <path
        d="M341.922 284.944a2.188 2.188 0 00-2.008 1.932l-.586 5.094-1.546 11.448a9.16 9.16 0 00-.754 4.077c.262 5.719 5.603 5.793 12.177 5.497 6.574-.295 11.875-.852 11.625-6.577-.249-5.725-5.799-10.121-12.372-9.826-.246.012-.49.031-.732.056l-3.424-10.127a2.325 2.325 0 00-2.38-1.574zM303.759 284.944a2.189 2.189 0 00-2.009 1.932l-.586 5.094-1.546 11.448a9.16 9.16 0 00-.754 4.077c.263 5.719 5.604 5.793 12.178 5.497 6.573-.295 11.874-.852 11.625-6.577-.249-5.725-5.799-10.121-12.373-9.826-.246.012-.49.031-.732.056l-3.423-10.127a2.326 2.326 0 00-2.38-1.574z"
        fill="#C3FFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M298.839 307.474h23.821a4.862 4.862 0 01-4.805 5.611h-13.404a5.611 5.611 0 01-5.612-5.611zM360.839 307.474h-23.821a4.862 4.862 0 004.805 5.611h13.404a5.612 5.612 0 005.612-5.611z"
        fill="#633DFF"
      />
      <path
        d="M461.924 291.358c11.338-4.127 17.183-16.663 13.057-28-4.127-11.338-16.663-17.183-28-13.057-11.338 4.127-17.183 16.663-13.057 28 4.127 11.338 16.663 17.183 28 13.057z"
        fill="#E4E8FF"
      />
      <path
        d="M444.475 274.461a2.362 2.362 0 10-1.614-4.439 2.362 2.362 0 001.614 4.439zM463.982 267.361a2.362 2.362 0 10-1.615-4.44 2.362 2.362 0 001.615 4.44z"
        fill="#C7CFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M444.253 279.029l22.725-8.271c.495 4.581-2.528 8.062-9.067 10.442-6.539 2.38-11.092 1.657-13.658-2.171z"
        fill="#C7CFFF"
      />
      <path
        d="M520.847 229.33c12.914 7.456 29.427 3.031 36.883-9.883 7.456-12.914 3.031-29.427-9.883-36.883-12.914-7.455-29.427-3.031-36.882 9.883-7.456 12.914-3.032 29.427 9.882 36.883z"
        fill="#E4E8FF"
      />
      <path
        d="M522.983 199.386a2.919 2.919 0 102.918-5.056 2.919 2.919 0 00-2.918 5.056zM545.202 212.214a2.919 2.919 0 102.92-5.054 2.919 2.919 0 00-2.92 5.054z"
        fill="#C7CFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M518.481 202.805l25.885 14.944c-3.945 4.108-9.641 4.012-17.09-.288-7.449-4.301-10.381-9.186-8.795-14.656z"
        fill="#C7CFFF"
      />
      <defs>
        <linearGradient
          id="igAiCoHostFAQ3_svg__paint0_linear_4235_199473"
          x1={-552.51}
          y1={3685.22}
          x2={-552.51}
          y2={6058.46}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#F9FFFE" />
        </linearGradient>
        <linearGradient
          id="igAiCoHostFAQ3_svg__paint1_linear_4235_199473"
          x1={1203.13}
          y1={3685.22}
          x2={1203.13}
          y2={6058.46}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#F9FFFE" />
        </linearGradient>
        <linearGradient
          id="igAiCoHostFAQ3_svg__paint2_linear_4235_199473"
          x1={-6134.15}
          y1={8232.47}
          x2={-6134.15}
          y2={13711.1}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#C1FFFF" />
        </linearGradient>
        <linearGradient
          id="igAiCoHostFAQ3_svg__paint4_linear_4235_199473"
          x1={-1363.79}
          y1={1899.04}
          x2={-1363.79}
          y2={3609.51}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#1493AB" />
        </linearGradient>
        <linearGradient
          id="igAiCoHostFAQ3_svg__paint5_linear_4235_199473"
          x1={-654.368}
          y1={1746.84}
          x2={-654.368}
          y2={3302}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#1493AB" />
        </linearGradient>
        <linearGradient
          id="igAiCoHostFAQ3_svg__paint6_linear_4235_199473"
          x1={-717.343}
          y1={1746.84}
          x2={-717.343}
          y2={3302}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#1493AB" />
        </linearGradient>
        <linearGradient
          id="igAiCoHostFAQ3_svg__paint7_linear_4235_199473"
          x1={1488.55}
          y1={236.38}
          x2={1488.55}
          y2={2652.75}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBFFFF" />
          <stop offset={1} stopColor="#E3FFFE" />
        </linearGradient>
        <linearGradient
          id="igAiCoHostFAQ3_svg__paint8_linear_4235_199473"
          x1={-835.607}
          y1={236.38}
          x2={-835.607}
          y2={2652.75}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBFFFF" />
          <stop offset={1} stopColor="#E3FFFE" />
        </linearGradient>
        <radialGradient
          id="igAiCoHostFAQ3_svg__paint3_radial_4235_199473"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-5067.7974 6022.8951 -4426.79152 -3724.80047 -705.326 3321)"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#fff" />
        </radialGradient>
      </defs>
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgIgAiCoHostFaq3);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
