import {
  COLOR_BACKGROUND_BLACK,
  COLOR_BACKGROUND_BLACK_DARK,
  COLOR_BACKGROUND_BLACK_DARKER,
  COLOR_BACKGROUND_BLACK_DARKER_PRESSED,
  COLOR_BACKGROUND_BLACK_DARKEST,
  COLOR_BACKGROUND_WHITE_DARK,
  COLOR_BACKGROUND_WHITE_DARKEST,
  COLOR_NEUTRAL_700,
  COLOR_OVERLAY_90,
  COLOR_STROKE_BLACK,
  COLOR_STROKE_BLACK_DARKER,
  COLOR_STROKE_BLACK_LIGHTER,
  COLOR_TEXT_WHITE_PRIMARY,
  COLOR_TEXT_WHITE_SECONDARY,
  COLOR_TEXT_WHITE_TERTIARY,
} from '@17live/design-system/color';

import breakpoints from './breakpoints';
import * as themeColors from './colors';
import * as fonts from './fonts';
import * as grids from './grids';
import * as radius from './radius';
import { WINDOW_HEIGHT, WINDOW_WIDTH } from './sizes';

// normal definitions
const NORMAL = {
  TEXT_DARKEST: themeColors.BD_BLACK,
  TEXT_DARKER: themeColors.BD_LIGHT_BLACK,
  TEXT_LIGHT: themeColors.BD_LIGHTEN_BLACK,
  BORDER: themeColors.BD_LIGHT_GRAY,
};

// theme definitions
export const THEATER = {
  TEXT_LIGHTEST: COLOR_TEXT_WHITE_PRIMARY,
  TEXT_LIGHTER: COLOR_TEXT_WHITE_SECONDARY,
  TEXT_DARK: COLOR_TEXT_WHITE_TERTIARY,
  BORDER: COLOR_STROKE_BLACK,
  BG: COLOR_BACKGROUND_BLACK_DARKER,
  BG_LIGHT: COLOR_BACKGROUND_BLACK,
  BG_LIGHTER: COLOR_BACKGROUND_BLACK,
  LIGHT_GRAY: COLOR_NEUTRAL_700,
};

export const theater = colors => ({
  ...colors,
  isTheater: true,
  BORDER_MAIN: THEATER.BORDER,
  BORDER_LIGHT: THEATER.BORDER,
  BORDER_LIGHTER: THEATER.BORDER,
  BORDER_BRIGHT: THEATER.BORDER,
  BORDER_THUMBNAIL: COLOR_STROKE_BLACK_DARKER,
  DARK_BUTTON_BORDER: colors.BD_BRIGHT_GRAY,
  BG_MAIN: THEATER.BG,
  BG_LIGHT: THEATER.BG_LIGHT,
  BG_LIGHTER: THEATER.BG_LIGHTER,
  BG_TRANSPARENT: 'rgba(255, 255, 255, 0.9)',
  BG_NAVITEM: THEATER.BG,
  BG_HOVER: THEATER.BG_LIGHT,
  BG_ACTIVE: THEATER.BG_LIGHT,
  BG_COUNTDOWN: '#2f2935', // 沒在用
  BG_MOTION: 'rgba(255, 255, 255, 0.03)',
  BG_DARK_MOTION: 'rgba(255, 255, 255, 0.1)',
  BG_GIFT_BOX: COLOR_BACKGROUND_BLACK,
  BG_RED_ENVELOPE: COLOR_BACKGROUND_BLACK_DARKER,
  BG_LUCKY_BAG: THEATER.BG,
  BG_JACK_POT: COLOR_BACKGROUND_BLACK_DARKER,
  BG_SEPARATOR: THEATER.BORDER,
  BG_DARK_BUTTON: THEATER.BG,
  BG_BLACK: colors.BG_WHITE,
  BG_SUBMIT_CHAT: THEATER.BG_LIGHTER,
  BG_DISABLED_SUBMIT_CHAT: COLOR_BACKGROUND_BLACK_DARKER,
  BG_THUMBNAIL: 'rgba(255, 255, 255, 0.03)',
  TEXT_MAIN: THEATER.TEXT_LIGHTER,
  TEXT_INVERT: colors.BD_BLACK,
  TEXT_ACTIVE: THEATER.TEXT_LIGHTEST,
  TEXT_INACTIVE: THEATER.TEXT_DARK,
  TEXT_HOVER: '#b8abc4',
  TEXT_GIFT: '#716080',
  TEXT_LUCKY_BAG: COLOR_TEXT_WHITE_TERTIARY,
  TEXT_LUCKY_BAG_ACTIVE: THEATER.TEXT_LIGHTER,
  TEXT_GIFT_TAB: COLOR_TEXT_WHITE_TERTIARY,
  TEXT_TITLE: THEATER.TEXT_LIGHTER,
  EMPTY_BG_COLOR: COLOR_BACKGROUND_WHITE_DARK,
  EMPTY_LINE_COLOR: colors.BD_LIGHT_BLACK,
  EMPTY_SHADOW_COLOR: '#e3dfe6',
  COUNTDOWN_COLOR: colors.BD_LIGHTEN_BLACK,
  TIME_COLOR: THEATER.TEXT_DARK,
  LEVEL_BORDER_COLOR: THEATER.BORDER,
  GIFT_HOVER_MASK: THEATER.BG,
  SNACKBAR_SEPARATOR: 'rgba(0, 0, 0, 0.12)',
  BORDER_MORE_BUTTON: '#000000',
  CHAT_ROOM: {
    BG: THEATER.BG,
  },
  DEFAULT_COMMENTS: {
    TEXT: THEATER.TEXT_LIGHTER,
    BORDER: THEATER.BORDER,
    BG: '#392f42',
    BG_HOVER: '#544561',
    BG_ACTIVE: '#1a161f',
  },
  GIFT_BOX: {
    BG_TAB: COLOR_BACKGROUND_BLACK_DARK,
    TEXT: colors.BD_BLACK,
    BG_BUTTON: colors.BG_BLACK,
  },
  TEXT_AREA: {
    TEXT: THEATER.TEXT_DARK,
    TEXT_FOCUS: THEATER.TEXT_LIGHTEST,
    TEXT_DISABLED: THEATER.TEXT_DARK,
    BORDER: THEATER.BORDER,
    BORDER_FOCUS: COLOR_STROKE_BLACK_LIGHTER,
    BORDER_DISABLED: THEATER.BORDER,
    BG: COLOR_BACKGROUND_BLACK_DARKER,
    BG_FOCUS: COLOR_BACKGROUND_BLACK_DARKER_PRESSED,
    BG_DISABLED: COLOR_BACKGROUND_BLACK_DARKEST,
  },
});

export const normal = colors => ({
  ...colors,
  isTheater: false,
  BORDER_MAIN: NORMAL.BORDER,
  BORDER_LIGHT: colors.BD_LIGHT_GRAY,
  BORDER_LIGHTER: colors.BG_DARKEN_WHITE,
  BORDER_BRIGHT: colors.BD_LIGHT_GRAY,
  BORDER_THUMBNAIL: colors.BD_LIGHT_GRAY,
  DARK_BUTTON_BORDER: 'transparent',
  BG_MAIN: colors.BG_DARKER_WHITE,
  BG_LIGHT: colors.BG_WHITE,
  BG_LIGHTER: colors.BG_WHITE,
  BG_TRANSPARENT: COLOR_OVERLAY_90,
  BG_NAVITEM: colors.BG_DARKER_WHITE,
  BG_HOVER: colors.BG_DARKER_WHITE,
  BG_ACTIVE: colors.BD_BORDER_WHITE,
  BG_COUNTDOWN: colors.BG_WHITE,
  BG_MOTION: colors.BG_PLACEHOLDER,
  BG_DARK_MOTION: colors.BG_DARK_PLACEHOLDER,
  BG_GIFT_BOX: colors.BG_WHITE,
  BG_RED_ENVELOPE: colors.BG_DARK_WHITE,
  BG_LUCKY_BAG: colors.BG_WHITE,
  BG_JACK_POT: COLOR_BACKGROUND_WHITE_DARKEST,
  BG_SEPARATOR: colors.BD_BORDER_WHITE,
  BG_DARK_BUTTON: colors.BD_BLACK,
  BG_BLACK: COLOR_BACKGROUND_BLACK_DARKEST,
  BG_SUBMIT_CHAT: colors.BG_WHITE,
  BG_DISABLED_SUBMIT_CHAT: colors.BG_DARKEN_WHITE,
  BG_THUMBNAIL: colors.BD_BORDER_WHITE,
  TEXT_MAIN: colors.BD_LIGHT_BLACK,
  TEXT_INVERT: colors.BG_WHITE,
  TEXT_ACTIVE: colors.BD_BLACK,
  TEXT_INACTIVE: colors.BD_LIGHTEN_BLACK,
  TEXT_HOVER: THEATER.LIGHT_GRAY,
  TEXT_GIFT: THEATER.LIGHT_GRAY,
  TEXT_LUCKY_BAG: colors.BD_LIGHT_BLACK,
  TEXT_LUCKY_BAG_ACTIVE: colors.BD_BLACK,
  TEXT_GIFT_TAB: colors.BD_LIGHTEN_BLACK,
  TEXT_TITLE: colors.BD_BLACK,
  EMPTY_BG_COLOR: colors.BG_WHITE,
  EMPTY_LINE_COLOR: '#aaa4b1',
  EMPTY_SHADOW_COLOR: '#e3e1e6',
  COUNTDOWN_COLOR: colors.BD_LIGHT_BLACK,
  TIME_COLOR: THEATER.BD_LIGHT_BLACK,
  LEVEL_BORDER_COLOR: colors.BD_LIGHT_GRAY,
  GIFT_HOVER_MASK: 'rgba(255, 255, 255, 0.8)',
  SNACKBAR_SEPARATOR: 'rgba(255, 255, 255, 0.12)',
  BORDER_MORE_BUTTON: colors.BD_BORDER_DARKEN_WHITE,
  CHAT_ROOM: {
    BG: colors.BG_WHITE,
  },
  DEFAULT_COMMENTS: {
    TEXT: NORMAL.TEXT_DARKER,
    BORDER: NORMAL.BORDER,
    BG: colors.BG_DARKEN_WHITE,
    BG_HOVER: colors.BG_WHITE,
    BG_ACTIVE: colors.BG_LIGHT_GRAY,
  },
  GIFT_BOX: {
    BG_TAB: colors.BG_WHITE,
    TEXT: colors.BD_WHITE,
    BG_BUTTON: colors.BG_WHITE,
  },
  TEXT_AREA: {
    TEXT: NORMAL.TEXT_LIGHT,
    TEXT_FOCUS: NORMAL.TEXT_DARKEST,
    TEXT_DISABLED: NORMAL.TEXT_LIGHT,
    BORDER: NORMAL.BORDER,
    BORDER_FOCUS: colors.BD_DIM_GRAY,
    BORDER_DISABLED: NORMAL.BORDER,
    BG: colors.BG_DARKEN_WHITE,
    BG_FOCUS: colors.BG_DARK_WHITE,
    BG_DISABLED: colors.BG_LIGHT_GRAY,
  },
});

const theme = {
  ...themeColors,
  ...fonts,
  ...grids,
  WINDOW_WIDTH,
  WINDOW_HEIGHT,
  ...radius,
  colors: {
    ...themeColors,
  },
  breakpoints,
};

export default theme;
