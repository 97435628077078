import * as React from 'react';
function SvgIgAiCoHostFaq4(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 720 361"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M334.89 332.946c86.657 0 163.096-11.099 201.459-65.852 38.363-54.752 9.867-227.297-205.675-214.47-215.543 12.828-201.303 179.5-163.825 221.008 37.478 41.509 81.383 59.314 168.041 59.314z"
        fill="#F1F4FF"
      />
      <path
        d="M344.973 314.761c48.482 0 87.784-3.358 87.784-7.5 0-4.142-39.302-7.5-87.784-7.5-48.482 0-87.784 3.358-87.784 7.5 0 4.142 39.302 7.5 87.784 7.5z"
        fill="#DEE3FF"
      />
      <path
        d="M343.282 310.255c33.966 0 61.5-1.903 61.5-4.25s-27.534-4.25-61.5-4.25c-33.965 0-61.5 1.903-61.5 4.25s27.535 4.25 61.5 4.25z"
        fill="#3F5CFF"
        fillOpacity={0.3}
      />
      <path
        d="M280.339 213.794c-8.595 4.804-14.908 10.162-18.939 16.074-4.031 5.912-5.381 14.782-4.049 26.611l16.012-7.21c-1.301-8.422-.464-14.889 2.512-19.401 2.976-4.512 6.367-7.414 10.172-8.706l-5.708-7.368z"
        fill="url(#igAiCoHostFAQ4_svg__paint0_linear_4235_199474)"
      />
      <path
        d="M272.693 262.57c6.002-5.411 7.66-13.355 3.704-17.743-3.957-4.387-12.029-3.557-18.03 1.854-6.002 5.411-7.66 13.355-3.704 17.743 3.957 4.387 12.029 3.557 18.03-1.854z"
        fill="#000"
      />
      <path
        d="M324.627 265.7a3.114 3.114 0 012.858 2.75l.835 7.251 2.2 16.293a13.056 13.056 0 011.074 5.805c-.374 8.139-7.977 8.244-17.333 7.824-9.357-.421-16.902-1.213-16.547-9.362.355-8.149 8.254-14.406 17.611-13.986.35.017.697.044 1.04.079l4.874-14.413a3.311 3.311 0 013.388-2.241zM378.947 265.7a3.115 3.115 0 012.858 2.75l.835 7.251 2.2 16.293a13.042 13.042 0 011.073 5.805c-.373 8.139-7.976 8.244-17.332 7.824-9.357-.421-16.902-1.213-16.547-9.362.355-8.149 8.254-14.406 17.611-13.986.35.017.696.044 1.04.079l4.874-14.413a3.311 3.311 0 013.388-2.241z"
        fill="#C3FFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M385.949 297.768h-33.905a6.92 6.92 0 006.838 7.987h19.08a7.987 7.987 0 007.987-7.987zM297.702 297.768h33.905a6.92 6.92 0 01-6.838 7.987H305.69a7.987 7.987 0 01-7.988-7.987z"
        fill="#633DFF"
      />
      <path
        d="M281.738 156.161l-13.489 4.462a11.727 11.727 0 00-8.044 11.134v33.64c0 4.757 2.875 9.044 7.277 10.85l14.256 5.848v-65.934z"
        fill="url(#igAiCoHostFAQ4_svg__paint1_linear_4235_199474)"
      />
      <mask
        id="igAiCoHostFAQ4_svg__a"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={260}
        y={156}
        width={22}
        height={67}
      >
        <path
          d="M281.738 156.161l-13.489 4.462a11.727 11.727 0 00-8.044 11.134v33.64c0 4.757 2.875 9.044 7.277 10.85l14.256 5.848v-65.934z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#igAiCoHostFAQ4_svg__a)">
        <path
          d="M270.971 156.161h-10.766v65.934h10.766v-65.934z"
          fill="#4E47FF"
        />
      </g>
      <path
        d="M420.914 156.161l13.488 4.462a11.726 11.726 0 018.044 11.134v33.64c0 4.757-2.874 9.044-7.276 10.85l-14.256 5.848v-65.934z"
        fill="url(#igAiCoHostFAQ4_svg__paint2_linear_4235_199474)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M341.568 119.995c-9.729-7.737-18.289-13.446-25.681-17.127-11.089-5.522-35.955-8.25-43.959-2.905-8.004 5.346-5.449 28.493-2.319 42.958 2.086 9.644 5.182 18.699 9.287 27.166.153-11.652 4.475-22.388 12.965-32.209 8.49-9.822 25.059-15.783 49.707-17.883z"
        fill="#fff"
      />
      <path
        d="M307.846 124.537c4.327-2.704-23.354-24.706-32.477-17.629-9.124 7.077 2.582 52.72 4.383 48.572 1.801-4.148 2.172-8.057 8.624-16.334 6.453-8.278 15.144-11.906 19.47-14.609z"
        fill="#908CFF"
      />
      <mask
        id="igAiCoHostFAQ4_svg__b"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={271}
        y={105}
        width={38}
        height={51}
      >
        <path
          d="M307.846 124.537c4.327-2.704-23.354-24.706-32.477-17.629-9.124 7.077 2.582 52.72 4.383 48.572 1.801-4.148 2.172-8.057 8.624-16.334 6.453-8.278 15.144-11.906 19.47-14.609z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#igAiCoHostFAQ4_svg__b)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M314.975 127.851c4.327-2.704-23.354-24.706-32.478-17.629-9.123 7.077 2.583 52.72 4.384 48.572 1.801-4.148 2.171-8.057 8.624-16.334 6.453-8.278 15.144-11.906 19.47-14.609z"
          fill="#4E47FF"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M361.896 120.012c9.729-7.736 18.289-13.446 25.681-17.127 11.088-5.522 35.955-8.25 43.958-2.904 8.004 5.345 5.45 28.492 2.32 42.958-2.087 9.644-5.182 18.699-9.287 27.165-.154-11.651-4.476-22.387-12.965-32.209-8.49-9.821-25.059-15.782-49.707-17.883z"
        fill="#fff"
      />
      <path
        d="M395.617 124.554c-4.326-2.703 23.355-24.706 32.478-17.629 9.124 7.078-2.583 52.721-4.384 48.573-1.8-4.148-2.171-8.057-8.624-16.335-6.453-8.277-15.143-11.905-19.47-14.609z"
        fill="#908CFF"
      />
      <mask
        id="igAiCoHostFAQ4_svg__c"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={395}
        y={105}
        width={37}
        height={51}
      >
        <path
          d="M395.617 124.554c-4.326-2.703 23.355-24.706 32.478-17.629 9.124 7.078-2.583 52.721-4.384 48.573-1.8-4.148-2.171-8.057-8.624-16.335-6.453-8.277-15.143-11.905-19.47-14.609z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#igAiCoHostFAQ4_svg__c)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M388.488 127.868c-4.326-2.703 23.355-24.706 32.478-17.629 9.124 7.078-2.583 52.721-4.383 48.573-1.801-4.148-2.172-8.057-8.625-16.335-6.452-8.277-15.143-11.905-19.47-14.609z"
          fill="#4E47FF"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M332.243 140.613l3.331 4.318a6.116 6.116 0 004.841 2.379h18.749a6.114 6.114 0 004.782-2.303l3.503-4.394h-35.206z"
        fill="#211F8A"
      />
      <path
        d="M379.619 271.864a794.393 794.393 0 01-56.586 0c-22.616-.973-43.274-19.081-44.384-38.906-.766-16.532-.766-56.856 0-73.392 1.11-19.811 21.768-37.933 44.384-38.906a800.344 800.344 0 0156.586 0c22.616.973 43.274 19.095 44.384 38.906.765 16.536.765 56.86 0 73.392-1.11 19.825-21.768 37.933-44.384 38.906z"
        fill="url(#igAiCoHostFAQ4_svg__paint3_linear_4235_199474)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M336.692 242.495l3.029-8.085a3.288 3.288 0 016.204.133l2.557 7.828a3.289 3.289 0 002.237 2.144l7.139 2.004a2.805 2.805 0 01.125 5.365l-7.41 2.458a3.288 3.288 0 00-2.074 2.053l-2.589 7.535a3.287 3.287 0 01-6.168.137l-3.072-7.799a3.292 3.292 0 00-1.994-1.905l-7.246-2.481a2.813 2.813 0 01.128-5.363l6.97-2.019a3.292 3.292 0 002.164-2.005z"
        fill="#4E47FF"
      />
      <path
        d="M381.131 135.11h-68.848c-16.887 0-30.576 13.689-30.576 30.576v32.002c0 16.887 13.689 30.576 30.576 30.576h68.848c16.887 0 30.576-13.689 30.576-30.576v-32.002c0-16.887-13.689-30.576-30.576-30.576z"
        fill="#221CBF"
      />
      <path
        d="M380.325 137.148h-67.236c-15.761 0-28.537 12.777-28.537 28.538v32.002c0 15.761 12.776 28.538 28.537 28.538h67.236c15.761 0 28.537-12.777 28.537-28.538v-32.002c0-15.761-12.776-28.538-28.537-28.538z"
        fill="#645EFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M418.855 157.75h1.161c8.223 0 14.889 6.666 14.889 14.889v30.772c0 8.223-6.666 14.889-14.889 14.889h-1.161c-8.223 0-14.889-6.666-14.889-14.889v-30.772c0-8.223 6.666-14.889 14.889-14.889z"
        fill="#4E47FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M421.436 163.434a8.189 8.189 0 018.189 8.189v30.771a8.189 8.189 0 01-8.189 8.189 8.189 8.189 0 01-8.189-8.189v-30.771a8.189 8.189 0 018.189-8.189z"
        fill="#908CFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M418.436 164.368a5.432 5.432 0 015.433 5.433v35.349a5.433 5.433 0 11-10.867 0v-35.349a5.433 5.433 0 015.434-5.433z"
        fill="#221CBF"
      />
      <path
        d="M379.566 140.613h-65.718c-13.509 0-24.461 10.952-24.461 24.461V198.3c0 13.509 10.952 24.46 24.461 24.46h65.718c13.509 0 24.46-10.951 24.46-24.46v-33.226c0-13.509-10.951-24.461-24.46-24.461z"
        fill="url(#igAiCoHostFAQ4_svg__paint4_radial_4235_199474)"
      />
      <path
        d="M339.06 197.992c10.643 0 19.27-8.598 19.27-19.203 0-10.606-8.627-19.203-19.27-19.203-10.643 0-19.271 8.597-19.271 19.203 0 10.605 8.628 19.203 19.271 19.203z"
        fill="url(#igAiCoHostFAQ4_svg__paint5_linear_4235_199474)"
      />
      <path
        d="M333.572 171.169a3.478 3.478 0 003.484-3.472 3.478 3.478 0 00-3.484-3.471 3.478 3.478 0 00-3.485 3.471 3.478 3.478 0 003.485 3.472z"
        fill="#00FFFA"
        fillOpacity={0.3}
      />
      <path
        d="M317.815 180.556c.054 9.64-3.314 17.436-11.462 17.436-9.25 0-11.609-7.843-11.655-17.482-.046-9.64 2.428-17.437 11.47-17.437 8.333 0 11.601 7.866 11.647 17.483z"
        fill="url(#igAiCoHostFAQ4_svg__paint6_linear_4235_199474)"
      />
      <path
        d="M305.027 172.89c1.745 0 3.16-1.465 3.16-3.273 0-1.807-1.415-3.272-3.16-3.272s-3.16 1.465-3.16 3.272c0 1.808 1.415 3.273 3.16 3.273z"
        fill="#fff"
      />
      <path
        d="M306.846 194.843c3.193 0 5.782-1.706 5.782-3.81s-2.589-3.81-5.782-3.81c-3.192 0-5.781 1.706-5.781 3.81s2.589 3.81 5.781 3.81z"
        fill="#00CBCD"
      />
      <path
        d="M356.985 200.688a1.542 1.542 0 112.455 1.866c-3.444 4.528-9.679 6.802-17.527 6.83-7.178 0-13.025-2.225-17.443-6.677a1.541 1.541 0 112.188-2.172c3.814 3.843 8.867 5.766 15.25 5.766 6.972-.025 12.307-1.97 15.077-5.613z"
        fill="#000"
      />
      <path
        d="M325.451 198.246c.85.052 1.496.784 1.444 1.634a5.495 5.495 0 01-2.496 4.271 1.542 1.542 0 01-1.678-2.587 2.41 2.41 0 001.096-1.874 1.543 1.543 0 011.634-1.444zM358.118 198.246a1.542 1.542 0 00-1.444 1.634 5.495 5.495 0 002.496 4.271 1.542 1.542 0 001.678-2.587 2.412 2.412 0 01-1.097-1.874 1.541 1.541 0 00-1.633-1.444z"
        fill="#000"
      />
      <path
        d="M376.882 229.446c22.091 0 40-17.909 40-40 0-22.092-17.909-40-40-40-22.092 0-40 17.908-40 40 0 22.091 17.908 40 40 40z"
        fill="url(#igAiCoHostFAQ4_svg__paint7_linear_4235_199474)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M378 148.24c22.091 0 40 17.909 40 40a39.868 39.868 0 01-3.323 15.988 40.07 40.07 0 001.205-9.782c0-22.092-17.909-40-40-40-16.407 0-30.506 9.877-36.676 24.009 4.365-17.361 20.078-30.215 38.794-30.215z"
        fill="#fff"
      />
      <path
        d="M378 140.24c26.51 0 48 21.491 48 48 0 26.51-21.49 48-48 48s-48-21.49-48-48c0-26.509 21.49-48 48-48zm0 8c-22.091 0-40 17.909-40 40 0 22.092 17.909 40 40 40s40-17.908 40-40c0-22.091-17.909-40-40-40z"
        fill="#100C76"
      />
      <path
        d="M407.382 172.399c2.485-2.962 1.928-7.521-1.246-10.184-3.173-2.662-7.759-2.42-10.244.542-2.485 2.961-1.928 7.521 1.245 10.183 3.173 2.663 7.76 2.42 10.245-.541z"
        fill="#fff"
      />
      <path
        d="M391.391 186.274c.068 14.172-4.884 25.634-16.861 25.634-13.599 0-16.998-11.53-17.134-25.701-.136-14.172 3.569-25.634 16.862-25.634 12.261 0 17.054 11.563 17.133 25.701z"
        fill="url(#igAiCoHostFAQ4_svg__paint8_linear_4235_199474)"
      />
      <path
        d="M372.58 175.005c2.566 0 4.646-2.154 4.646-4.811 0-2.656-2.08-4.81-4.646-4.81s-4.646 2.154-4.646 4.81c0 2.657 2.08 4.811 4.646 4.811z"
        fill="#fff"
      />
      <path
        d="M375.255 207.278c4.694 0 8.499-2.508 8.499-5.601s-3.805-5.601-8.499-5.601c-4.694 0-8.499 2.508-8.499 5.601s3.805 5.601 8.499 5.601z"
        fill="#00CBCD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M250.321 220.75l-5.107-16.018 52.488-1.875 2.015 17.893h-49.396z"
        fill="#C7CFFF"
      />
      <path
        d="M420.914 217.494c8.071-.375 13.064.732 14.978 3.321 1.914 2.589 1.585 7.738-.987 15.446l-11.412-2.021"
        fill="url(#igAiCoHostFAQ4_svg__paint9_linear_4235_199474)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M256.255 202.132a8.001 8.001 0 0110.258-2.667l37.567 19.07 5.307 32.355a3 3 0 01-3.163 3.479l-49.996-3.397a3.002 3.002 0 01-2.689-2.197l-8.325-30.24 11.041-16.403z"
        fill="#fff"
      />
      <path
        d="M261.303 204.798c3.302 0 5.979-2.775 5.979-6.2 0-3.424-2.677-6.2-5.979-6.2s-5.98 2.776-5.98 6.2c0 3.425 2.678 6.2 5.98 6.2z"
        fill="#FF7171"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M407.214 223.409l23.263 22.461a3 3 0 004.242-.074l.042-.045 4.862-5.246a3 3 0 00-.279-4.343l-24.995-20.85-7.135 8.097z"
        fill="#100C76"
      />
      <path
        d="M414.722 235.411c4.287 6.85 11.822 9.862 16.83 6.728 5.008-3.134 5.592-11.228 1.305-18.078s-11.822-9.862-16.83-6.728c-5.008 3.134-5.592 11.228-1.305 18.078z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M167.867 157.25h66.721l3.254 34.253a4.539 4.539 0 01-4.385 4.966l-56.157 1.66a4.54 4.54 0 01-4.636-3.952l-4.797-36.927z"
        fill="#E4E8FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M167.867 157.25l35.088 22.405a3.63 3.63 0 004.238-.238l27.395-22.167h-66.721z"
        fill="#C7CFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M540.705 97.077h-73.5l-3.584 37.734a5 5 0 004.83 5.47l61.864 1.828a5 5 0 005.106-4.353l5.284-40.679z"
        fill="#E4E8FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M540.705 97.077l-38.652 24.681a4 4 0 01-4.669-.261l-30.179-24.42h73.5z"
        fill="#C7CFFF"
      />
      <defs>
        <linearGradient
          id="igAiCoHostFAQ4_svg__paint0_linear_4235_199474"
          x1={1716.44}
          y1={213.794}
          x2={1716.44}
          y2={4482.29}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBFFFF" />
          <stop offset={1} stopColor="#E3FFFE" />
        </linearGradient>
        <linearGradient
          id="igAiCoHostFAQ4_svg__paint1_linear_4235_199474"
          x1={1336.83}
          y1={4085.19}
          x2={1336.83}
          y2={6749.57}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#F9FFFE" />
        </linearGradient>
        <linearGradient
          id="igAiCoHostFAQ4_svg__paint2_linear_4235_199474"
          x1={-634.178}
          y1={4085.19}
          x2={-634.178}
          y2={6749.57}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#F9FFFE" />
        </linearGradient>
        <linearGradient
          id="igAiCoHostFAQ4_svg__paint3_linear_4235_199474"
          x1={7603.19}
          y1={9190.27}
          x2={7603.19}
          y2={15341}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#C1FFFF" />
        </linearGradient>
        <linearGradient
          id="igAiCoHostFAQ4_svg__paint5_linear_4235_199474"
          x1={2246.84}
          y1={2079.89}
          x2={2246.84}
          y2={4000.19}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#1493AB" />
        </linearGradient>
        <linearGradient
          id="igAiCoHostFAQ4_svg__paint6_linear_4235_199474"
          x1={1450.61}
          y1={1909.01}
          x2={1450.61}
          y2={3654.95}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#1493AB" />
        </linearGradient>
        <linearGradient
          id="igAiCoHostFAQ4_svg__paint7_linear_4235_199474"
          x1={7225.5}
          y1={604.504}
          x2={2389.39}
          y2={7484.55}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BFFFE" />
          <stop offset={1} stopColor="#F4FFFE" />
        </linearGradient>
        <linearGradient
          id="igAiCoHostFAQ4_svg__paint8_linear_4235_199474"
          x1={2057.39}
          y1={2727.29}
          x2={2057.39}
          y2={5294.01}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#1493AB" />
        </linearGradient>
        <linearGradient
          id="igAiCoHostFAQ4_svg__paint9_linear_4235_199474"
          x1={1231.08}
          y1={217.423}
          x2={1231.08}
          y2={2101.28}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBFFFF" />
          <stop offset={1} stopColor="#E3FFFE" />
        </linearGradient>
        <radialGradient
          id="igAiCoHostFAQ4_svg__paint4_radial_4235_199474"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(49.923 -3160.772 3557.326) scale(9124.04 6314.81)"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#fff" />
        </radialGradient>
      </defs>
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgIgAiCoHostFaq4);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
