/**
 * reference:
 * https://github.com/17media/wiki/blob/master/pages/Feature-Design/groupcall.md
 */

export type GroupCallInfo = {
  id: string; // Group call ID
  topics: string[];
  seqNo: number; // For client to compare the order of the change when receive an updated info
  startTime: number;
  endTime: number;
  creator: string; // creator's userID
  referralCode: string; // host's referralCode for invitation via deeplink
  members: Member[];
};

enum MemberStatus {
  none = 0,
  raisingHands = 1,
  invited = 2,
  queuing = 3,
  connecting = 4,
  reserved = 5,
  ready = 6,
  rejected = 7,
  notAvailable = 8,
}

export type Member = {
  userID: string;
  roomID: number; // For joining agora channel/sending pubnub
  displayName: string;
  picture: string;
  isMute: boolean;
  updatedAt: number;
  isHost: boolean;
  isOnline: boolean;
  status: MemberStatus;
};

/**
 * GroupCallAction
 * refs https://github.com/17media/wiki/blob/master/pages/Feature-Design/groupcall.md#action
 * models/groupcall/groupcall.go
 */
export enum GroupCallAction {
  none = 0,
  invite = 1,
  add = 2,
  approve = 3,
  kickout = 4,
  accept = 5,
  decline = 6,
  raiseHand = 7,
  cancelRaiseHand = 8,
  dequeue = 9,
  confirm = 10,
  reject = 11,
  leave = 12,
  close = 13,
  ready = 14,
  forceDisconnect = 15,
  mute = 16,
  unmute = 17,
}

/**
 * pubnub type = 88
 * - Notify the change of the members in the call to streamers and viewers
 * - Send Invitation/Approval to target guest
 * - Notify the member doesn't join the call, remove the user from agora channel
 */
export type GroupCallActionMsg = {
  id: string; // Group call ID
  action: GroupCallAction;
  userID: string; // the userID related to the action
  displayName: string;
  groupCallInfo: GroupCallInfo;
};

export type GroupCallActionPayload = {
  roomID: string;
  message: {
    groupCallActionMsg: GroupCallActionMsg;
  };
};

/**
 * pubnub type = 89
 * Notify the host that the status of a user has changed
 */
export type GroupCallMemberStatusMsg = {
  id: string; // Group call ID
  updatedAt: number;
  status: MemberStatus;
  userID: string;
  displayName: string;
  action: GroupCallAction;
};

export type GroupCallMemberStatusPayload = {
  roomID: string;
  message: {
    groupCallMemberStatusMsg: GroupCallMemberStatusMsg;
  };
};

export enum ErrorCode {
  'GROUP_CALL_TOO_MANY_MEMBERS' = 53001,
  'GROUP_CALL_WRONG_MEMBER_STATUS' = 53002,
  'GROUP_CALL_TOO_MANY_INVITATIONS' = 53003,
  'GROUP_CALL_ALREADY_RAISINGHAND' = 53004,
  'GROUP_CALL_ALREADY_QUEUING' = 53005,
}
