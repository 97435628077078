import React from 'react';

import styled from 'styled-components';

import { $t } from '@17live/app/utils';
import { COLOR_NEUTRAL_700 } from '@17live/design-system/color';
import {
  Heading4,
  Heading6,
  TextBody2,
} from '@17live/design-system/Typography/Typography';
import IgAiCoHostFaq1 from '@17live/ui/Gui/IgAiCoHostFaq1';
import IgAiCoHostFaq2 from '@17live/ui/Gui/IgAiCoHostFaq2';
import IgAiCoHostFaq3 from '@17live/ui/Gui/IgAiCoHostFaq3';
import IgAiCoHostFaq4 from '@17live/ui/Gui/IgAiCoHostFaq4';
import IgAiCoHostFaq5 from '@17live/ui/Gui/IgAiCoHostFaq5';

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  white-space: pre-wrap;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const AIAssistantIntroduction = () => {
  return (
    <Wrapper>
      <Heading4>{$t('ai_assistant_info_subtitle')}</Heading4>
      <Section>
        <Heading6>{$t('ai_assistant_info_heading_5')}</Heading6>
        <TextBody2 color={COLOR_NEUTRAL_700}>
          {$t('ai_assistant_info_desc_5')}
        </TextBody2>
        <IgAiCoHostFaq5 width={'100%'} height={'100%'} />
      </Section>
      <Section>
        <Heading6>{$t('ai_assistant_info_heading_1')}</Heading6>
        <TextBody2 color={COLOR_NEUTRAL_700}>
          {$t('ai_assistant_info_desc_1')}
        </TextBody2>
        <IgAiCoHostFaq1 width={'100%'} height={'100%'} />
      </Section>
      <Section>
        <Heading6>{$t('ai_assistant_info_heading_2')}</Heading6>
        <TextBody2 color={COLOR_NEUTRAL_700}>
          {$t('ai_assistant_info_desc_2')}
        </TextBody2>
        <IgAiCoHostFaq2 width={'100%'} height={'100%'} />
      </Section>
      <Section>
        <Heading6>{$t('ai_assistant_info_heading_3')}</Heading6>
        <TextBody2 color={COLOR_NEUTRAL_700}>
          {$t('ai_assistant_info_desc_3')}
        </TextBody2>
        <IgAiCoHostFaq3 width={'100%'} height={'100%'} />
      </Section>
      <Section>
        <Heading6>{$t('ai_assistant_info_heading_4')}</Heading6>
        <TextBody2 color={COLOR_NEUTRAL_700}>
          {$t('ai_assistant_info_desc_4')}
        </TextBody2>
        <IgAiCoHostFaq4 width={'100%'} height={'100%'} />
      </Section>
    </Wrapper>
  );
};

export default AIAssistantIntroduction;
